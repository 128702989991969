import httpUtil from "@/utils/httpUtil";

/**字典 */
export const getByDictType = async params => httpUtil.post("/api/crmPc/sysDict/getByDictType", params);

/** 获取地区数据 */
export const getTbArea = async params => httpUtil.post("/api/crmPc/tbArea/getTbArea", params)

/** 通过地区码获取父级 */
export const getParentByCode = async params => httpUtil.post("/api/crmPc/tbArea/getParentByCode", params)

/**获得社团信息列表 */
export const getAssociationManageList = params => httpUtil.post("/api/crmPc/association/getAssociationManageList", params);

/**获得社团信息详情 */
export const getAssociationManage = params => httpUtil.post("/api/crmPc/association/getAssociationManage", params);

/**添加/修改社团 */
export const setAssociationManage = params => httpUtil.post("/api/crmPc/association/setAssociationManage", params);

/**添加或修改社团设置 */
export const setAssociationOption = params => httpUtil.post("/api/crmPc/association/setAssociationOption", params);

/**获得社团设置 */
export const getAssociationOption = params => httpUtil.post("/api/crmPc/association/getAssociationOption", params);

/**获得关联社团的商品信息 */
export const getGoodsListByLink = params => { params.accountType = 9; return httpUtil.post("/api/crmPc/member/getGoodsListByLink", params) };

/**进驻分类 */
export const getParkShopTypeLinkList = async(params = {}) => {
    params.accountType = 9;
    return httpUtil.post("/api/crmPc/property/getParkShopTypeLinkList", params)
};

/**新增关联分类 */
export const insertParkShopTypeLink = async params => httpUtil.post("/api/crmPc/property/insertParkShopTypeLink", params);

/**修改关联分类 */
export const updateParkShopTypeLink = async params => httpUtil.post("/api/crmPc/property/updateParkShopTypeLink", params);

/**设置进驻商家配置 */
export const setParkIntoOption = (params) => httpUtil.post("/api/crmPc/property/setParkIntoOption", params)

/**获取设置进驻商家配置 */
export const getParkIntoOption = (params) => httpUtil.post("/api/crmPc/property/getParkIntoOption", params)

/**获取园区进驻商家列表 */
export const getParkIntoCompanyList = (params = {}) => {
    params.accountType = 9;
    return httpUtil.post("/api/crmPc/property/getParkIntoCompanyList", params)
}

/**获取园区进驻商家详情 */
export const getParkIntoCompany = (params) => httpUtil.post("/api/crmPc/property/getParkIntoCompany", params)

/**审核进驻商家 */
export const updateParkIntoCompany = (params) => httpUtil.post("/api/crmPc/property/updateParkIntoCompany", params)

/**动态分类列表 */
export const getContentTypeList = (params) => httpUtil.post("/api/crmPc/sysDict/getContentTypeList", params)

/**设置动态分类 */
export const setContentType = (params) => httpUtil.post("/api/crmPc/sysDict/setContentType", params)

/**社团动态 */
export const getAssociationMessageList = (params) => httpUtil.post("/api/crmPc/association/getAssociationMessageList", params)

/**设置社团动态 */
export const setAssociationMessage = (params) => httpUtil.post("/api/crmPc/association/setAssociationMessage", params)

/**设置社团动态 */
export const delAssociationMessage = (params) => httpUtil.post("/api/crmPc/association/delAssociationMessage", params)

/**获得商品会员价 */
export const getGoodsMemberPriceList = (params = {}) => { params.accountType = 9; return httpUtil.post("/api/crmPc/companyGoods/getGoodsMemberPriceList", params) }

/**批量设置商品会员价 */
export const setAllGoodsMemberPrice = (params = {}) => { params.accountType = 9; return httpUtil.post("/api/crmPc/companyGoods/setAllGoodsMemberPrice", params) }

/** 获取广告分类 */
export function fetchPhotoType(params) {
    return httpUtil.post("/api/crmPc/companyPhoto/selectTypeNum", params)
}

/** 获取广告 */
export function selectCompanyPhoto(params) {
    return httpUtil.post("/api/crmPc/companyPhoto/selectCompanyPhoto", params)
}

/** 新增/编辑广告 */
export function insertCompanyPhoto(params) {
    return httpUtil.post("/api/crmPc/companyPhoto/insertCompanyPhoto", params)
}




/**活动订单 */
export function getActivityRegisterOrderList(params) {
    return httpUtil.post("/api/crmPc/activityEvent/getActivityRegisterOrderList", params)
}

/**退款 */
export function updateRefundState(params) {
    params.refundState = 3;
    return httpUtil.post("/api/crmPc/activityEvent/updateRefundState", params)
}

/**商品订单 */
export function selectOrdersInfoPc(params) {
    return httpUtil.post("/api/crmPc/orders/selectOrdersInfoPc", params)
}

/**进驻商家订单 */
export function getParkIntoOrderList(params) {
    return httpUtil.post("/api/crmPc/property/getParkIntoOrderList", params)
}

/**获取条款 */
export function getAgreeMessage(params) {
    return httpUtil.post("/api/crmPc/normal/getAgreeMessage", params)
}
/**设置条款 */
export function setAgreeMessage(params) {
    return httpUtil.post("/api/crmPc/normal/setAgreeMessage", params)
}


/**新建小程序钱包互通记录 */
export function addAccountWalletLink(params) {
    return httpUtil.post("/api/crmPc/userWallet/addAccountWalletLink", params)
}
/**删除或取消小程序钱包互通 */
export function delAccountWalletLink(params) {
    return httpUtil.post("/api/crmPc/userWallet/delAccountWalletLink", params)
}
/**获得小程序钱包互通列表 */
export function getAccountWalletLinkList(params) {
    return httpUtil.post("/api/crmPc/userWallet/getAccountWalletLinkList", params)
}
/**修改小程序钱包互通 */
export function updateAccountWalletLink(params) {
    return httpUtil.post("/api/crmPc/userWallet/updateAccountWalletLink", params)
}